import { useAuth } from '@/hooks/auth';
import useOnBoarding from '@/hooks/useOnboarding';
import { LinearProgress, styled } from '@formbio/ui';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { QueryParams, getHref, navRoutes } from './routes';

const StyledDiv = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
}));

export default function withRequiredAuth<T extends object>(
  WrappedComponent: React.ComponentType<T>,
) {
  return function WithRequiredAuth(props: T) {
    const router = useRouter();
    const { user, loading: isUserLoading } = useAuth();
    const { isPending, isOnboarding } = useOnBoarding();

    useEffect(() => {
      if (!isUserLoading && !user) {
        router.push({
          pathname: navRoutes.login.pathname,
          query: { [QueryParams.destination]: router.asPath },
        });
      }
      // TODO: uncomment if/when email verification is enabled
      // Decided as part of the ISA implementation we didn't need to verify emails
      // // user's email isn't verified, send them back to check-verify-email
      // if (user && !user.emailVerified) {
      //   router.push({
      //     pathname: navRoutes.verifyEmail.pathname,
      //   });
      // }
    }, [isUserLoading, user, router.asPath]);

    /*
     * Check if user has an account id but not org or project yet.
     * If so, redirect to onboarding page
     */
    useEffect(() => {
      if (!isPending && isOnboarding) {
        router.push(getHref(router, navRoutes.onboarding));
      }
    }, [isOnboarding, isPending]);

    if (!user || (!isPending && isOnboarding)) {
      return (
        <StyledDiv>
          <LinearProgress />
        </StyledDiv>
      );
    }
    return <WrappedComponent {...props} />;
  };
}
