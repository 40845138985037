import { useAuth } from '@/hooks/auth';
import { getHref, navRoutes } from '@/utils/routes';
import {
  AvatarMenuHeader,
  AvatarPicture,
  Badge,
  bindMenu,
  bindTrigger,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  NextMuiLink,
  PopupState,
  PopupStateType,
  styled,
} from '@formbio/ui';
import {
  CaretDownFilledBackgroundIcon,
  Dna as DnaIcon,
  SignOut as SignOutIcon,
  User as UserIcon,
} from '@formbio/ui/components/Icons';
import { AvatarProps } from '@formbio/ui/types';
import { useRouter } from 'next/router';
import React from 'react';

const StyledMenu = styled(Menu)(({ theme }) => ({
  '.MuiList-root': {
    paddingTop: 0,
  },
  '.MuiDivider-root': {
    marginBottom: theme.spacing(1),
  },
  a: {
    textDecoration: 'none',
  },
  'a:hover': {
    textDecoration: 'none',
  },
  '.caret-down': {
    border: '2px solid white',
    borderRadius: '4px',
  },
}));

const StyledBadge = styled(CaretDownFilledBackgroundIcon)(() => ({
  border: '2px solid white',
  borderRadius: '4px',
  fontSize: 'xlarge',
}));

export default function AppAvatarMenu() {
  const { user } = useAuth();
  const router = useRouter();

  const avatar: AvatarProps = {
    displayName: user?.displayName || 'display name',
    image: user?.photoURL || undefined,
    email: user?.email || undefined,
  };

  async function handleLogout() {
    router.push(navRoutes.logout.pathname, undefined, { shallow: true });
  }

  return (
    <PopupState variant='popover' popupId='demo-popup-menu'>
      {(popupState: PopupStateType) => (
        <React.Fragment>
          <IconButton aria-label='Account options' {...bindTrigger(popupState)}>
            <AvatarPicture avatar={avatar} border />
            <Badge badgeContent={<StyledBadge className='caret-down' />} />
          </IconButton>
          <StyledMenu
            {...bindMenu(popupState)}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <AvatarMenuHeader avatar={avatar} />
            <Divider />
            {/* My Profile Navigation Item */}
            <NextMuiLink nextHref={getHref(router, navRoutes.myProfile)}>
              <MenuItem>
                <ListItemIcon>
                  <UserIcon />
                </ListItemIcon>
                <ListItemText primary='My Profile' />
              </MenuItem>
            </NextMuiLink>
            <NextMuiLink nextHref={navRoutes.synthAssaysLanding.pathname}>
              <MenuItem onClick={popupState.close}>
                <ListItemIcon>
                  <DnaIcon />
                </ListItemIcon>
                <ListItemText primary='Go to In Silico Assays' />
              </MenuItem>
            </NextMuiLink>
            <MenuItem
              onClick={() => {
                popupState.close;
                handleLogout();
              }}
            >
              <ListItemIcon>
                <SignOutIcon />
              </ListItemIcon>
              <ListItemText primary='Log Out' />
            </MenuItem>
          </StyledMenu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
